
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "Verification",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "signIn" }));
    };

    const sendVerificationEmail = () => {
      store
        .dispatch(Actions.EMAIL_VERIFICATION_NOTIFICATION)
        .then(() => {
          Swal.fire({
            text: "Email verification link sent successfully.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          // Alert then login failed
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const verifyNow = () => {
      store.dispatch(Actions.VERIFY_AUTH).then(() => {
        router.push({ name: "dashboard" });
      });
    };

    return {
      currentUser,
      sendVerificationEmail,
      verifyNow,
      signOut,
    };
  },
});
